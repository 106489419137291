import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { v4 as uuidv4 } from "uuid";
import firebase from "./firebase";

const sendMessage = firebase.functions().httpsCallable("sendMessage");

function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

const StyledField = styled(Field)`
  background: transparent;
  //   border: 1px solid rgba(0, 0, 0, 0.05);
  border: 0;
  -webkit-appearance: none;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  resize: none;
  color: #f0ead6;
  overflow: hidden;
  width: 100%;
  font-size: 24px;
  text-align: center;

  &.show {
    color: #f0ead6;
  }
  &.hide {
    color: transparent;
  }
  &:focus {
    outline: none;
  }

  transition: 0.3s all ease-out;
`;

const ResizeField = ({ field, form, ...props }) => (
  <TextareaAutosize {...field} {...props} />
);

const Label = styled.label`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HoverForm = props => {
  const [status, setStatus] = useState("show");
  const fieldRef = useRef(null);
  const labelRef = useRef(null);
  const [uid, setUid] = useState(null);

  //   useEffect(() => {
  //     console.log("el", props.pageRef.current);
  //     props.pageRef.current &&
  //       props.pageRef.current.addEventListener(
  //         "click",
  //         e => {
  //           console.log("WHAT UP", e, labelRef.current);
  //           e.stopPropagation();
  //           e.preventDefault();
  //           labelRef.current && labelRef.current.click();
  //         },
  //         false
  //       );
  //   });

  useEffect(() => {
    if (!uid) {
      setUid(uuidv4());
    }
  });

  return (
    <div>
      <Formik
        initialValues={{ message: "" }}
        onSubmit={(values, actions) => {
          setStatus("hide");
          setTimeout(() => {
            actions.resetForm();
            setStatus("show");
          }, 300);
          if (values.message !== "") {
            sendMessage({
              username: uid,
              message: values.message,
              webhookURL: props.webhookURL
            }).then(result => {
              // console.log("Result", result);
            });
          }
        }}
      >
        {props => (
          <Form>
            <Label htmlFor="message" ref={labelRef}>
              <div
                style={{
                  alignSelf: "flex-end",
                  width: "100%",
                  height: "33.33%",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <StyledField
                  // ref={fieldRef}
                  className={status}
                  name="message"
                  autoFocus={!isMobileDevice()}
                  id="message"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  component={ResizeField}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      props.submitForm();
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }
                  }}
                />
              </div>
            </Label>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HoverForm;
