import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import HoverChat from "./HoverChat";
import HoverVideo from "./HoverVideo";
import HoverForm from "./HoverForm";
import styled from "styled-components";
import firebase from "./firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { Portal } from "react-portal";
import { useWindowSize } from "@react-hook/window-size/throttled";
import Player from "@vimeo/player";
import canAutoPlay from "can-autoplay";
import Countdown from "./Countdown";

const desktop = 1024;
const tablet = 768;

// const HoverVideoWrapper = styled.div`
//   flex: 1 1 auto;
//   display: flex;
//   position: relative;
//   justify-content: center;
//   align-items: center;
//   padding-bottom: 58.25%;
// `;

const tabletQuery = `(orientation: portrait) and (min-width: ${tablet}px) and (max-width: ${desktop -
  1}px)`;
const desktopQuery = `(min-width: ${desktop}px)`;

const OuterFrame = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  // min-height: 100vh;
  overflow: hidden;
  position: relative;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media ${tabletQuery} {
    // display: block;
  }

  @media ${desktopQuery} {
    padding: 0;
  }
`;

const Stage = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;
  // margin: 80px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // top: 85px;
  // bottom: 85px;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  // width: 100%;
  flex: 1 1 auto;
  align-items: center;
  position: fixed;
  top: 60px;
  bottom: 60px;
  left: 10px;
  right: 10px;
  // height: 100vh;
  // top: -1000px;
  // padding-bottom: 56.25%;
  // height: 0;
  opacity: 1;
  z-index: 1;
  // iframe {
  //   width: 100%;
  //   height: 100%;
  // }
  overflow: hidden;

  > div > div::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    pointer-events: none;
  }
`;

const Nameplate = styled.img`
  // position: fixed;
  // top: 16%;
  // left: 50%;
  width: 360px;
  height: 54px;
  // transform: translate(-50%, 0);
  z-index: 5;
  margin: 0 auto;
  flex: 0 0 auto;

  @media (max-width: 500px) {
    width: 270px;
    height: 39px;
  }
`;

const Play = styled.div`
  position: fixed;
  z-index: 9999;
  display: block;
  width: 90px;
  height: 90px;
  left: 50%;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    zoom: 0.75;
  }
`;

const Cover = styled.img`
  width: 90%;
  height: 1000px;
  align-self: center;
  margin: 0 auto;
  height: auto;
  display: block;
  z-index: 10;
  object-fit: contain;
  max-height: 50vh;
`;

const CountdownWrapper = styled.div`
  display: block;
  position: fixed;
  font-family: "Open Sans", sans-serif;
  color: #f0ead6;
  text-align: center;
  font-variant-numeric: tabular-nums;
  width: 100%;
  font-size: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  span {
    display: block;
    margin-top: 200px;
  }
`;

const NewNameplate = styled.div`
  background-size: 360px 54px;
  width: 100%;
  flex: 0 0 auto;
  min-width: 270px;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/dgp.png);
  z-index: 1000;
  max-height: 40%;
  min-height: 100px;

  @media (max-width: 500px) {
    background-size: 270px 39px;
  }
`;

const Links = styled.div`
  display: block;
  flex: 0 0 auto;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 40%;
  min-height: 100px;
`;

const Link = styled.a`
  font-family: "Open Sans", sans-serif;
  color: #f0ead6;
  // width: 100%;
  font-size: 24px;
  text-decoration: none;
  margin: 0 20px;
`;

const BasicLayout = props => {
  const { config } = props;
  const [width, height] = useWindowSize();
  const isMobile = window.matchMedia(`(max-width: ${desktop - 1}px)`).matches;
  const isDesktop = window.matchMedia(desktopQuery).matches;
  const isTablet = window.matchMedia(tabletQuery).matches;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [activeNav, setActiveNav] = useState(
    (isDesktop && "chat") ||
      (!!config.showDonationForm && "donate") ||
      (!!config.showChat && "chat") ||
      ""
  );
  const wrapperEl = useRef(null);
  const [videoHeight, setVideoHeight] = useState("100%");
  const [videoWidth, setVideoWidth] = useState("100%");

  const [showPlay, setShowPlay] = useState(true);

  const [showMobileFooter, setShowMobileFooter] = useState(false);
  // console.log({ isMobile, isTablet, isDesktop, isPortrait });

  const pageRef = useRef(null);

  const playerRef = useRef(null);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [eventsSet, setEventsSet] = useState(false);

  const calculateWrapper = () => {
    const player = document.querySelector("iframe#player");
    // console.log(player);
    if (player) {
      setVideoHeight(player.clientHeight);
      setVideoWidth(player.clientWidth);
      // console.log(player.clientHeight, player.clientWidth);
    }
  };

  window.calculateWrapper = calculateWrapper;

  useEffect(() => {
    calculateWrapper();
  }, [height, width]);

  setTimeout(calculateWrapper, 500);
  setTimeout(calculateWrapper, 250);
  setTimeout(calculateWrapper, 1000);
  setTimeout(calculateWrapper, 5000);

  let player = null;

  // useEffect(() => {
  //   if (playerRef && playerRef.current) {
  //     console.log("Loading player!");
  //     calculateWrapper();
  //     player = new Player(playerRef.current, {
  //       id: config.videoId,
  //       width: "100%",
  //       height: "100%",
  //       controls: false,
  //       playsinline: true,
  //       autoplay: false,
  //       muted: true
  //     });
  //   }
  // }, [playerRef.current]);

  if (!config.afterMidnight) {
    return (
      <OuterFrame ref={pageRef} style={{ height: window.innerHeight }}>
        <CountdownWrapper>
          <Nameplate src="/dgp.png" />
          <span>
            <Countdown countdownEnd={config.countdownEnd.seconds} />
          </span>
        </CountdownWrapper>
      </OuterFrame>
    );
  }

  if (config.endState) {
    return (
      <OuterFrame ref={pageRef} style={{ height: window.innerHeight }}>
        <CountdownWrapper>
          <Nameplate src="/dgp.png" />
          <span>
            <Link href={config.musicURL} target="_blank">
              Music
            </Link>
          </span>
        </CountdownWrapper>
        {config.customCSS && <style>{config.customCSS}</style>}
      </OuterFrame>
    );
  }

  return (
    <OuterFrame ref={pageRef} style={{ height: window.innerHeight }}>
      <CountdownWrapper>
        <NewNameplate />
        <Cover className="image" src={config.imageURL} />
        <VideoWrapper className="videoWrapper" rel={wrapperEl}>
          <HoverVideo url={config.videoURL} />
        </VideoWrapper>
        <Links>
          <Link href={config.musicURL} target="_blank">
            Music
          </Link>
        </Links>
        {config.customCSS && <style>{config.customCSS}</style>}
      </CountdownWrapper>
    </OuterFrame>
  );
};

const Layout = props => {
  let { pageSlug } = useParams();

  const [hasLoaded, setHasLoaded] = useState(false);

  const pagesCollection = firebase.firestore().collection("pages");

  const query = props.root
    ? pagesCollection.where("hostname", "==", window.location.hostname)
    : pagesCollection.where("pageSlug", "==", pageSlug);

  const [pages, pagesLoading, pagesError] = useCollectionData(query);
  const [portals, setPortals] = useState([]);

  useEffect(() => {
    setHasLoaded(false);
  }, [pages]);

  if (pagesError) return <div>{pagesError}</div>;
  if (pagesLoading) return <div>&nbsp;</div>;
  if (!pages || pages.length === 0) return <div />;

  let config = pages[0];

  let output = <BasicLayout config={config} />;

  if (config.layout === "custom") {
    let customOutput = DOMPurify.sanitize(config.customLayout, {
      ALLOWED_TAGS: ["div", "span", "img", "p", "hovervideo"],
      ALLOWED_ATTR: ["url", "class", "style"]
    });

    output = (
      <div
        style={{ width: "100%" }}
        dangerouslySetInnerHTML={{ __html: customOutput }}
      />
    );
  }

  return (
    <Fragment>
      {output}
      {portals}
    </Fragment>
  );
};

export default Layout;
