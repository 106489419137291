import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faComment,
  faComments,
  faTimes,
  faCaretUp,
  faCaretDown
} from "@fortawesome/pro-solid-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
library.add(faComment, faComments, faTimes, faTwitch, faCaretUp, faCaretDown);
