import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Layout from './Layout'
import * as serviceWorker from './serviceWorker'
import './icons'
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom'

const el = document.createElement('div')
document.body.appendChild(el)

// ReactDOM.render(<App />, el);
ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/page/:pageSlug">
        <Layout />
      </Route>
      <Route exact path="/">
      <Layout root />
      </Route>
    </Switch>
  </Router>,
  el
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
