import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyAncoYPOqn4hQ7SdKIKMXmxHm49BJBBM24",
  authDomain: "hovercast-landing.firebaseapp.com",
  databaseURL: "https://hovercast-landing.firebaseio.com",
  projectId: "hovercast-landing",
  storageBucket: "hovercast-landing.appspot.com",
  messagingSenderId: "514877686144",
  appId: "1:514877686144:web:2b1adf520413bf885c0165",
  measurementId: "G-40CC653Z6Q"
};

export const FIREBASE_ERRORS = {
  "auth/user-not-found": "Email not found.",
  "auth/wrong-password": "Password incorrect, please try again."
};

firebase.initializeApp(config);

export default firebase;
