import React, { useEffect, useState } from "react";
import moment from "moment";

// export const getRemaining = ({ endTime, hideHours, overrideTime }) => {
//   // console.log('Get remaining', endTime, hideHours, overrideTime)
//   let et = buildMomentFromTime(endTime);

//   let secs = overrideTime || Math.max(0, et.diff(moment(), "seconds"));

//   // console.log('secs', this.props.endTime, secs)

//   // if (this.props.onEnd) this.props.onEnd(!secs)

//   let mins = Math.floor(secs / 60);
//   secs -= mins * 60;

//   let hrs = Math.floor(mins / 60);
//   mins -= hrs * 60;

//   if (secs < 10) secs = "0" + secs;
//   if (mins < 10) mins = "0" + mins;

//   if (hideHours && hrs === 0) return `${mins}:${secs}`;
//   return `${hrs}:${mins}:${secs}`;
// };

const Countdown = props => {
  const buildTime = () => {
    // moment().format(props.countdownFormat || "HH:mm:ss");

    let et = moment(props.countdownEnd * 1000);

    let total = Math.max(0, et.diff(moment()));

    let duration = moment.duration(total);

    let days = duration.days(),
      hrs = duration.hours(),
      mins = duration.minutes(),
      secs = duration.seconds();

    return `${days.toString().padStart(2, "0")}:${hrs
      .toString()
      .padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  const [text, setText] = useState(buildTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setText(buildTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return text;
};

export default Countdown;
