import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";
import Player from "@vimeo/player";

const VideoWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  width: 100%;
  // min-height: 200px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
  }

  #player {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const Play = styled.div`
  position: fixed;
  z-index: 9999;
  display: block;
  width: 90px;
  height: 90px;
  left: 50%;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    zoom: 0.75;
  }
`;

const HoverVideo = props => {
  const wrapperEl = useRef(null);
  const playerEl = useRef(null);
  const [height, setHeight] = useState("100%");
  const [width, setWidth] = useState("100%");
  const [loaded, setLoaded] = useState(false);
  const [playerObj, setPlayerObj] = useState(null);
  const [showPlay, setShowPlay] = useState(false);
  // console.log("PARENT", wrapperEl, wrapperEl.current,);
  const playerRef = useRef(null);
  let player = null;

  useEffect(() => {
    // console.log(
    //   "PARENT",
    //   wrapperEl,
    //   wrapperEl.current,
    //   wrapperEl.current.parentElement.clientHeight,
    //   wrapperEl.current.parentElement.clientWidth
    // );

    const parentWidth = wrapperEl.current.parentElement.clientWidth;
    const parentHeight = wrapperEl.current.parentElement.clientHeight;
    let newHeight, newWidth;
    if (parentWidth / parentHeight > 16 / 9) {
      newHeight = parentHeight;
      newWidth = (parentHeight * 16) / 9;
    } else {
      newWidth = parentWidth;
      newHeight = (parentWidth * 9) / 16;
    }
    setHeight(newHeight);
    setWidth(newWidth);
    // console.log({ newHeight, newWidth });
  });
  // setTimeout(() => {
  // }, 1000);
  let embed = null;

  useEffect(() => {
    if (playerRef && playerRef.current) {
      console.log("Loading player!");
      player = new Player(playerRef.current, {
        id: props.url,
        width: "100%",
        height: "100%",
        controls: false,
        playsinline: true,
        autoplay: false,
        muted: true
      });

      player.on("play", () => setShowPlay(true));
      setPlayerObj(player);
    }
  }, [playerRef.current]);

  return (
    <VideoWrapper ref={wrapperEl}>
      <motion.div
        style={{ position: "absolute" }}
        animate={{ width, height }}
        transition={{ duration: 0.1 }}
      >
        <iframe
          id="player"
          ref={playerRef}
          src={props.url}
          frameBorder="0"
          allow="autoplay; fullscreen"
        />
        {showPlay && (
          <Play
            onClick={() => {
              console.log(playerObj);
              playerObj.setVolume(1);
              playerObj.play();
              playerObj.setMuted(false);
              setShowPlay(false);
            }}
          >
            <svg viewBox="0 0 466 526" version="1.1">
              <path
                d="M233,31.0172879 L-29.1436766,495.5 L495.143677,495.5 L233,31.0172879 Z"
                fill="#F0EAD6"
                transform="translate(233.000000, 263.000000) rotate(-270.000000) translate(-233.000000, -263.000000) "
              ></path>
            </svg>
          </Play>
        )}
      </motion.div>
    </VideoWrapper>
  );
};

export default HoverVideo;
